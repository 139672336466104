import React from 'react';
import { Link } from "gatsby"
import parse from 'html-react-parser';
import shortid from  "shortid";
import ReactPlayer from 'react-player';



const HomeProcessSection = (props) => {

    return (
        <section className="process-section">
            <div className="container">
                <div className='our-process'>
                    <div className="row">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-22">
                            <div className="row">
                                <div className="col-xl-24">
                                    <div className="title-block">
                                        <h2 className="h1 section-title extra-spacing">{props.mainTitle}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="image-block">
                                        <img
                                            src={props.image1x?.sourceUrl}
                                            srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                            width={props.image1x?.width}
                                            alt={props.image1x?.altText}
                                        />
                                        {/* <ReactPlayer
                                           className='react-player'
                                           id="video"
                                           url={props.VideoUrl}
                                           playing={true}
                                           loop={true} 
                                           muted={true}
                                           playsinline={true}
                                        //    light={true}
                                        /> */}
                                    </div>
                                </div>
                                <div className="col-xl-2"></div>
                                <div className="col-xl-14">
                                    <div className="content-block">
                                        <p className="section-description">{props.mainDescription}</p>
                                        <div className="btn-block"><Link to="/" className="btn outline-btn" >Our Process</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeProcessSection;